.main-gradient-color {
    background: linear-gradient(92.73deg, #FF00FF 0.61%, #FE511B 99.34%), linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.secondary-gradient-color {
    background: linear-gradient(90.1deg, #69EACB 0.09%, #6654F1 79.17%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.token-amount-input {
    font-size: 24px;
    max-width: calc(100% - 102px);
}

.gradient-btn {
    background: linear-gradient(92.73deg, #FF00FF 0.61%, #FE511B 99.34%), linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
}

.nowrap {
    text-wrap: nowrap;
}

.light-spread:hover {
    position: relative;
    cursor: pointer;
    border-radius: 100px;
    background: radial-gradient(circle at center, rgb(252, 211, 217), #ffffff);
    /* Replace lighterred with your desired lighter shade */
}

.dark-spread:hover {
    position: relative;
    cursor: pointer;
    border-radius: 100px;
    background: radial-gradient(circle at center, rgb(102, 65, 71), black);
    /* Replace lighterred with your desired lighter shade */
}

.hover:hover {
    opacity: 0.8;
}

.navmenu-visible {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s;
}

.navmenu-hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}